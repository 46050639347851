#technologies {
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	padding-top: 130px;
	padding-bottom: 130px;
}

.technology-title {
	font-size: 13px;
	letter-spacing: 1px;
	font-weight: 700;
	margin: 0;
	color: #565dab;
	text-transform: uppercase;
}
.technology-head {
	margin: 10px 0 0 0;
	padding: 0;
	font-size: 38px;
	line-height: 42px;
	font-weight: 700;
	color: #012970;
	text-transform: uppercase;
}
.swiper {
	width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	height: 60px;
	width: auto;

	border: 1px solid #444;

	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.swiper-slide i {
	font-size: 2.5rem;
	margin-right: 1rem;
	border-right: 1px solid #444;
	padding: 1rem 0.5rem;

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 100%;
	width: 30%;
}

.swiper-slide div {
	display: block;
	justify-content: center;
	align-items: center;
	text-align: center;
	/* padding: 0 0.5rem; */
	height: 100%;
	width: 70%;
}
.swiper-slide h4 {
	font-size: 12px;
	font-weight: bolder;
	text-shadow: 1px 0px black;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: inherit;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: -12px;
	left: 0;
	width: 100%;
}
