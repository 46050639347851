/* new styles */
#contact-us {
	width: 100%;
	min-height: 100vh;
	padding: 0;
	display: flex;
	flex-direction: column;
}

.contact {
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.contact .info-box {
	text-align: left;
	box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
	padding: 30px;
	border-radius: 0.5rem;
	border: 1px solid #565dab;
	color: #565dab;
	font-weight: bold;
	font-family: Poppins, 'Sansserif';
	transition: all 0.25s ease-in;
	cursor: pointer;
}
.contact .info-box i {
	font-size: 32px;
}
.contact .info-box h3 {
	font-size: 20px;

	font-weight: 700;
	margin: 10px 0;
}
.contact .info-box p {
	padding: 0;
	line-height: 24px;
	font-size: 14px;
	margin-bottom: 0;
}
.contact .php-email-form {
	box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
	padding: 30px;
	border-radius: 4px;
}
.contact .php-email-form .error-message {
	display: none;
	color: #fff;
	background: #ed3c0d;
	text-align: left;
	padding: 15px;
	font-weight: 600;
}
.contact .php-email-form .error-message br + br {
	margin-top: 25px;
}
.contact .php-email-form .sent-message {
	display: none;
	color: #fff;
	background: #18d26e;
	text-align: center;
	padding: 15px;
	font-weight: 600;
}
.contact .php-email-form .loading {
	display: none;
	background: #fff;
	text-align: center;
	padding: 15px;
}
.contact .php-email-form .loading:before {
	content: '';
	display: inline-block;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin: 0 10px -6px 0;
	border: 3px solid #18d26e;
	border-top-color: #eee;
	-webkit-animation: animate-loading 1s linear infinite;
	animation: animate-loading 1s linear infinite;
}
.contact .php-email-form .form-group {
	margin-bottom: 25px;
	position: relative;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
	box-shadow: none;
	font-size: 14px;
	border-radius: 1rem;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
	border-color: #565dab;
}
.contact .php-email-form input {
	padding: 10px 15px;
}
.contact .php-email-form textarea {
	padding: 12px 15px;
}
.contact .php-email-form button[type='submit'] {
	background: #565dab;
	border: 0;
	padding: 10px 32px;
	color: #fff;
	transition: 0.4s;
	border-radius: 4px;
	font-size: 1rem;
}
.contact .php-email-form button[type='submit']:hover {
	background: #565dab;
}
@-webkit-keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.form-control {
	border-radius: 1rem;
}
.form-control:focus,
.PhoneInput--focus {
	border: 2px solid #565dab !important;
	border-color: #565dab !important;
}
.contact select {
	font-size: 15px;
}

input {
	font-size: 14px;
	border: none;
	outline: none;
}

.contact .fonts {
	font-size: 1rem;
}

.contact .info-box:hover {
	background-color: #565dab;
	color: #fff;
	opacity: 0.9;
}

button:disabled {
	cursor: not-allowed;
	pointer-events: all !important;
}

.contact .modal {
	font-size: 12px;
}
.contact .modal > .header {
	width: 100%;
	border-bottom: 1px solid gray;
	font-size: 18px;
	text-align: center;
	padding: 5px;
}
.contact .modal > .content {
	width: 100%;
	padding: 10px 5px;
}
.contact .modal > .actions {
	width: 100%;
	padding: 10px 5px;
	margin: auto;
	text-align: center;
}
.contact .modal > .close {
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: -10px;
	top: -10px;
	font-size: 24px;
	background: #ffffff;
	border-radius: 18px;
	border: 1px solid #cfcece;
}
@keyframes anvil {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	1% {
		transform: scale(0.96) translateY(10px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
}
.contact .popup-content {
	animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

	-webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.contact .notification-container {
	min-width: 100px !important;
	height: auto !important;
	font-size: 1rem !important;
	background: #28a745 !important;
}

.contact .notification-body {
	font-size: 1rem !important;
	background: #28a745 !important;
	color: #fff;
}
.contact .toast-container {
	background: #28a745 !important;
	width: 100% !important;
}
.contact .toast-container button {
	color: #fff !important;
	font-size: 2rem;
	opacity: 1 !important;
}

.contact .success-animation {
	margin: 150px auto;
}

.contact .checkmark {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: #565dab;
	stroke-miterlimit: 10;
	box-shadow: inset 0px 0px 0px #565dab;
	animation: fill 1s ease-in-out 2s infinite, scale 1s ease-in-out 0.9s both;
	position: relative;
	top: 5px;
	right: 5px;
	margin: 0 auto;
}
.contact .checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #565dab;
	fill: #fff;
	animation: stroke 3s cubic-bezier(0.65, 0, 0.45, 1) infinite;
}

.contact .checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes scale {
	0%,
	100% {
		transform: none;
	}

	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #565dab;
	}
}

.breadcrumbs {
	padding: 50px 0 15px 0;
	background: #fff;
	min-height: 40px;
}
@media (max-width: 992px) {
	.breadcrumbs {
		margin-top: 130px;
	}
}
.breadcrumbs h2 {
	font-size: 28px;
	margin-bottom: 0;
	font-weight: 300;
}
.breadcrumbs ol {
	display: flex;
	flex-wrap: wrap;
	list-style: none;

	margin: 0;
	font-size: 1.5rem;
	color: #565dab;
}
.breadcrumbs ol li + li {
	padding-left: 10px;
}
.breadcrumbs ol li + li::before {
	display: inline-block;
	padding-right: 10px;
	color: #fff;
	content: '/';
}
@media (max-width: 768px) {
	.breadcrumbs .d-flex {
		display: block !important;
	}
	.breadcrumbs ol {
		display: block;
	}
	.breadcrumbs ol li {
		display: inline-block;
	}
}

/* New ANIMATION */
