.services-title-heading {
	font-size: 13px;
	letter-spacing: 1px;
	font-weight: 700;
	margin: 0;
	color: #565dab;
	text-transform: uppercase;
}
.services-sub-heading {
	margin: 10px 0 0 0;
	padding: 0;
	font-size: 38px;
	line-height: 42px;
	font-weight: 700;
	color: #012970;
	text-transform: uppercase;
}
.services-title-description {
	font-size: 16px;
}
.services .icon-box {
	padding: 30px;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	transition: transform 0.2s;
}
.services .icon-box:hover {
	background: #565dab;
	color: #fff !important;
	transform: scale(1);
	-webkit-box-shadow: 0px 18px 71px -10px rgb(0 0 0 / 75%);
	-moz-box-shadow: 0px 18px 71px -10px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 18px 71px -10px rgb(0 0 0 / 75%);
	border-radius: 25px;
}
.features .accordion-title {
	padding: 20px;
	width: 100%;
	background: #fff;
	border-radius: 4px;
	margin: 5px 5px 0 5px;
	color: #565dab;
	transition: all 0.25s ease-in-out;
	cursor: pointer;
}
.features .accordion-title:hover {
	background:#aed4f9;
	color: #fff;
	transition: all 0.25s ease-in-out;
	cursor: pointer;
}
.services .icon-box:hover .title {
	color: #fff;
}
.services .icon {
	position: absolute;
	left: 7px;
	top: calc(50% - 30px);
	margin-right: 200px;
}
.services .icon i {
	font-size: 64px;
	line-height: 1;
	transition: 0.5s;
}
.services .title {
	margin-left: 40px;
	font-weight: 700;
	margin-bottom: 15px;
	font-size: 18px;
	color: #565dab;
}
.services .title a {
	color: #2a2c39;
	transition: ease-in-out 0.3s;
}
.services .title a:hover {
	color: #ef6603;
}
.services .description {
	font-size: 14px;
	margin-left: 40px;
	line-height: 24px;
	margin-bottom: 0;
}
.service-title {
	position: relative;
	display: inline-block;
}

.service-icon {
	height: 50px;
	margin-bottom: 20px;
	font-size: 12px;
}
